export const projectsList = [
  {
    name: "Scales.io",
    date: "2022",
    slug: "scales-io",
    tagline: "A piano key visualizer for scales.",
    url: "https://scales-io.netlify.app/",
    writeup: "/scales-io",
    highlight: true,
  },
  {
    name: "Sencilla Theme",
    date: "2022",
    slug: "sencilla-theme",
    tagline: "Minimalistic, mobile-friendly one-page resume built with HTML, CSS, and JavaScript.",
    url: "https://eldoraboo.github.io/sencilla-theme/",
    writeup: "/sencilla-theme",
    highlight: true,
  },
  {
    name: "Trello-Postman Client",
    date: "2022",
    slug: "trello-postman",
    tagline: "Trello API optimised as a collection for Postman.",
    writeup: "/trello-postman",
    highlight: true,
  },
  {
    name: "Auto Labeling Pipeline",
    date: "2022",
    slug: "auto-labeling",
    tagline: "Auto Labeling Pipeline for Named Entity Recognition & Test Classification.",
    writeup: "/auto-labeling",
    highlight: true,
  },
  {
    name: "PairALegal",
    date: "2021",
    slug: "PairALegal",
    tagline:
      "Chatbot paired with a website depository filled with succinct profiles of lawyers offering their services.",
    url: "https://eldoraboo.github.io/PairALegal/",
    writeup: "/pair-a-legal",
    highlight: true,
  },
];
